body.export {
	color: #222;
}

.export td {
	vertical-align: top;
	padding: 10px;
}

.export hr {
	margin: 10px 10px;
	page-break-after: avoid;
	border: 0;
	border-top: 1px #666666 solid;
}

.export hr.dashed {
	border-style: dashed;
}

.export .label {
	text-transform: uppercase;
	color: darkgrey;
	font-weight: bold;
}

.export .quiet {
	color: darkgrey;
	font-size: 0.9em;
}

.export .strong {
	font-weight: 900;
}

.export h1 {
	padding: 0 10px;
	margin-top: 20px;
}

.export h4 {
	padding: 0 10px;
	margin-top: 20px;
}

.export h3 {
	clear: both;
	margin-top: 30px;
	page-break-after: avoid;
	padding: 0 10px;
}

.export .subtitle {
	padding: 0 10px;
}

.export .header {
	padding: 10px;
}

.export .column {
	width: calc(50% - 20px);
	margin-right: 20px;
	float: left;
}

.export .subtitle.important {
	font-weight: bold;
}

.export .logo {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.export .logo + span {
	vertical-align: middle;
}

.export .page {
	page-break-inside: avoid;
}

.export .page::after {
	content: '';
	display: table;
	clear: both;
}

@media screen {
	.export {
		width: 1200px;
		margin: auto;
	}
}

.print-info {
	text-align: center;
	background-color: yellow;
	color: black;
	clear: both;
	width: 700px;
	padding: 5px;
	margin: auto;
}

@media print {
	.export .print-info {
		display: none;
	}
}
