/*==========================================================================================
	BUTTONS
==========================================================================================*/
/*Base*/
button {
	position: relative;
	border: 2px solid var(--color-normal);
	border-radius: 3em; /* big enough to form a pill shape instead of ellipsis */
	padding: 5px 12px;
	font-weight: bold;
	color: var(--color-normal);
	background: transparent;
	cursor: pointer;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
	outline: none;
	line-height: 1.2;
}

/*Variants*/
button.quiet {
	border-color: transparent;
	color: var(--color-normal);
}

button.secondary {
	border-color: var(--color-secondary-button);
	color: var(--color-secondary-button);
}

button.cta {
	border-color: transparent;
	color: var(--color-light);
	background: var(--background-cta);
}

/*Hover states*/
/*Separate from their base or variant definitions for proper declaration cascading*/
button:hover {
	border-color: transparent;
	color: var(--color-light);
	background: var(--background-button-hover);
}

button.quiet:hover {
	color: var(--color-quiet-hover);
	background-color: var(--background-quiet-hover);
}

button.secondary:hover {
	background: var(--background-secondary-hover);
}

button.cta:hover {
	background: var(--background-cta-hover);
}

/*Active & focused states*/
/*Separate from their base or variant definitions for proper declaration cascading*/
button:active,
button:focus {
	border-color: transparent;
	color: var(--color-active-light);
	background: var(--background-button-active);
}

button.quiet:active,
button.quiet:focus {
	background-color: var(--background-quiet-active);
	color: var(--color-quiet-active);
}

button.secondary:active,
button.secondary:focus {
	background: var(--background-secondary-active);
	border-color: var(--color-secondary-button);
	color: var(--color-secondary-button);
}

button.cta:active,
button.cta:focus {
	background: var(--background-cta-active);
}

/*Disabled*/
button:disabled {
	border-color: transparent;
	color: var(--color-disabled) !important;
	background: var(--background-disabled);
	pointer-events: none;
}

button.quiet:disabled {
	background-color: transparent;
}

/*Add spacing between icons and labels.*/
button > i + span {
	margin-left: 0.3em;
}

/*Button that looks like regular text*/
button.text-button {
	background: transparent;
	border: none;
	margin: 0;
	padding: 0;
	border-radius: 0;
	font-weight: inherit;
}

button.inline-item {
	margin-left: 2em;
}

/*Button that is just an icon*/
button.icon-button > i {
	font-size: 1em;
}

/*Button that looks like a link*/
button.link-button {
	@extend button.text-button;
	@extend a;
}
