/*==========================================================================================
	GENERIC
==========================================================================================*/
th {
	font-weight: normal;
	text-align: left;
}

th + td {
	padding-left: 15px;
}

/*==========================================================================================
	MASTER TABLES
==========================================================================================*/
/*Master tables have large headers and generous spacing*/

.master-table {
	width: 100%;
	border-collapse: collapse;
}

/*Row bottom border*/
.master-table tbody tr {
	border-top: 1px solid var(--separator-light);
}

/*Cell padding*/
.master-table th,
.master-table td {
	padding-left: 5px;
	padding-right: 5px;
}

:root {
	--master-table-padding-left: 30px;
}

.master-table th:first-child,
.master-table td:first-child {
	padding-left: var(--master-table-padding-left);
}

.master-table th:last-child,
.master-table td:last-child {
	padding-right: 30px;
}

/*Table headers*/
.master-table th {
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 0.75rem;
	text-transform: uppercase;
	color: var(--color-table-header);
}

/*Table cells*/
.master-table td {
	padding-top: 15px;
	padding-bottom: 15px;
}

.master-table .numeric-content {
	text-align: right;
	padding-right: 15px;
}

.master-table.big th {
	padding-top: 35px;
	padding-bottom: 35px;
	background-color: var(--background-stained);
}

.master-table-header {
	padding-left: var(--master-table-padding-left);
	padding-bottom: 10px;
	font-weight: 400;
}
/*==========================================================================================
	FIXED HEADERS
==========================================================================================*/
/*Element which provides positioning context for .header-display-label elements*/
/*This is typically the immediate parent of the table, e.g. a <div>*/
.fixed-headers-table-context {
	position: relative;
	overflow: hidden;
}

/*Remove all vertical spacing from the fixed headers row and elements*/
.fixed-headers-table .fixed-headers-row {
	border-top: 0;
	border-bottom: 0;
}

.fixed-headers-table .fixed-headers-row th {
	height: 0;
	line-height: 0;
	padding-top: 0;
	padding-bottom: 0;
}

/*Hide header labels visually but perserve layout in case content is smaller than header*/
.fixed-headers-table .header-layout-label {
	visibility: hidden;
}

/*Visible header labels are positioned in relation to .fixed-headers-table-context*/
.fixed-headers-table .header-display-label {
	position: absolute;
	top: 0;
}

/*==========================================================================================
	TABLE DECORATORS
==========================================================================================*/

/*=== Hoverable ===*/
/*Hoverable tables highlight the currently hovered-on row by setting a background colour and pointer cursor*/
.hoverable tbody tr {
	transition: background-color 0.2s ease-in-out;
}

.hoverable tbody tr:hover {
	background-color: var(--background-stained);
}

/*Pointer cursor set on td intead of tr to allow turning it off for some td elements if ever required*/
.hoverable td {
	cursor: pointer;
}

/*=== Fixed Layout ===*/
.fixed-layout {
	table-layout: fixed;
}



.form-value-table {
	padding-left: 0;
	margin-left: 0.75em;
	width: 100%;
}
.form-value-table th, .form-value-table td {
	padding: 2px 2px 0 0;
	text-indent: 0;
}
.form-value-table th {
	font-size: 0.75rem;
	text-transform: uppercase;
	color: var(--color-table-header);
}

.form-value-table td button {
	margin-top: 2px;
}

td .form-value-table {
	margin-left: 0;
}

td .form-value-table th, td .form-value-table td {
	padding-left: 0 !important;
}

td .form-value-table thead tr {
	border-top: none;
}
