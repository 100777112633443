.agent-search-view {
	@extend .search-view;
}

.agent-search-view .quick-result-container /*header*/ .selected-agent-name {
	flex: 0 0 auto;
	font-size: 1.5rem;
	font-weight: 300;
	max-width: 100%;

	@media (max-width: 1000px) {
		font-size: 1.25rem;
	}
}
