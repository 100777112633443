/*==========================================================================================
	SYMMETRIC COLUMNS
==========================================================================================*/
.layout-columns {
	display: flex;
	flex-direction: row;
	align-items: top;
}

/* Responsiveness - one column layout */
@media(max-width: 1000px) {
	.layout-columns {
		display: block;
	}
}

.layout-columns > * {
	flex: 3;
	display:block;
}

/*==========================================================================================
	ASYMMETRIC COLUMNS
==========================================================================================*/
.layout-columns-asymmetric {
	@extend .layout-columns;
}

.layout-columns-asymmetric > :first-child {
	flex:1;
	text-align:right;
}

/* Responsiveness - one column layout */
@media (max-width: 1000px) {
	.layout-columns-asymmetric > :first-child {
		text-align:left;
	}
}

.layout-columns-asymmetric .layout-columns-asymmetric > :first-child {
	text-align:left;
}

/*==========================================================================================
	ROW
==========================================================================================*/
.row {
	display: flex;
	flex-direction: row;
}

.row i:first-child {
	margin-right: 10px;
}

.row.long {
	width: 800px;
}
.row.medium {
	width: 500px;
}
