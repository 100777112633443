.customer-container {
	display: flex;
	flex-direction: column;
}

.customer-header {
	flex: 0;
	position: relative;
	background-color: var(--background-header);
	color: var(--color-header);
	text-align: center;
	padding: 25px 20px 10px 20px;
}

.customer {
	flex: 1 1;
}

.customer-header .go-back-link {
	position: absolute;
	left: 20px;
}

/* Remove go-back-link for small widths */
@media (max-width: 500px) {
	.customer-header .go-back-link {
		display: none;
	}
}

.customer-header .customer-buttons {
	position: absolute;
	right: 20px;
}

.customer-header .customer-name {
	font-size: 2.5rem;
	font-weight: 400;
	color: var(--color-light);
}

.customer-header .customer-picture {
	position: relative;
	margin: 20px auto 0;
	width: 64px;
}

.customer-header .customer-picture.warning img {
	opacity: 0.2;
}

.customer-header .customer-picture.warning::after {
	content: '';
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 0;
	margin-left: -25px;
	background: url('error.svg') no-repeat;
}

/*=== Navigation ===*/
/* Layout and spacing */
.customer-header .customer-nav {
	padding: 15px 0;
}

.customer-header .customer-nav > ul {
	display: inline-flex;
	flex-direction: row;
}

.customer-header .customer-nav li {
	margin-right: 30px;
}

.customer-header .customer-nav li:last-child {
	margin-right: 0;
}

.customer-header .customer-nav li a {
	display: block;
	padding: 5px 0;
	border-bottom: 2px solid transparent; /*Prevent flicker due to border showing up on hover*/
}

/* Style */
.customer-header .customer-nav li {
	text-transform: uppercase;
}

.customer-header .customer-nav li a {
	font-size: 1rem;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
}

.customer-header .customer-nav li a.selected,
.customer-header .customer-nav li a:hover {
	color: var(--color-light);
	border-bottom-color: var(--color-light);
}

/* ================ ACCORDION =============== */
/* Layout and spacing */
.customer .accordion-title-main {
	display: inline-block;
	min-width: 20vw;
	margin: 10px 30px 10px 10px;
}

/* Style */
.customer .accordion-title-main {
	font-size: 1.25rem;
	font-weight: bold;
}

.customer .accordion-title-details {
	font-size: 1rem;
	font-weight: normal;
	color: var(--color-subtle);
}

.customer [abo-accordion-item].open .accordion-title-details {
	visibility: hidden;
}

/* ================ FORM =============== */
.customer .form-header {
	padding: 5px 20px;
}
.customer .form-field {
	padding: 5px 20px;
	min-height: 40px; /* Leave space for inline buttons */
}

.customer .form-field > :first-child {
	max-width: 200px;
}

.customer .form-field > :last-child {
	max-width: 500px;
}

.customer .form-value > button {
	margin-top: -5px;
}

.customer .form-field.buttons {
	padding-left: 273px; /* align with the text */
	display: block;
	min-height: 0;
}

.customer .form-field.buttons > * {
	display: inline-block;
	text-align: center;
	margin-top: 0;
	max-width: 500px;
}

.customer .form-field .form-field {
	padding: 0;
	min-height: 0;
}

.customer .form-field .form-field > span:first-child {
	padding: 0 0 3px;
	text-align: left;
}

.customer button {
	margin-right: 10px;
}

.customer button + explanation {
	margin-left: -10px;
}

.customer .form-field > span:first-child {
	padding: 5px 20px 8px;
}

.customer input,
.customer textarea,
.customer .dropdown,
.customer .form-value {
	width: 100%;
}

/* Responsiveness - labels on top, wider fields */
@media (max-width: 1000px) {
	.customer .form-field > span:first-child {
		padding: 5px 0;
	}

	.customer button, .auto-inline-item {
		display: block;
		width: 100%;
		margin: 5px 0 !important;
		text-align: center;
	}

	.customer .form-field.buttons {
		flex-direction: column;
		padding-left: 20px;
	}
}

/* ================ ACCORDION ITEMS =============== */
/* Layout */
.customer [abo-accordion-body] > div {
	margin: 20px 0;
}

/* Tables */
.customer-organizations tbody tr,
.customer-login-history tbody tr {
	border-bottom: 0;
	border-top: 1px solid var(--separator-light);
}

.customer-impersonation-session tbody tr {
	border-bottom: 0;
	border-top: 1px solid var(--separator-light);
}

/* Particularities */
.customer-addresses .abo-tab-body-container {
	margin: 10px 0;
}

.customer-addresses [abo-tab-title] {
	font-size: 1rem;
}

.customer-organizations-identifier {
	font-size: 0.75rem;
}

.customer .centered-buttons {
	padding: 20px 10px 10px 10px;
	text-align: center;
}

.customer .pristine-state-message,
.customer .empty-state-message {
	color: var(--color-subtle);
	font-size: 1.75rem;
	font-weight: 300;
	text-align: center;
}

.customer .pristine-state-message .pristine-state-image {
	max-height: 25vh;
	max-width: 50vw;
}

.customer .pristine-state-message h1,
.customer .empty-state-message h1 {
	font-size: 4rem;
	font-weight: 300;
}

/*Responsiveness*/
@media (max-width: 450px) {
	.customer .pristine-state-message
	.customer .empty-state-message {
		font-size: 2rem;
	}
	.customer .pristine-state-message h1,
	.customer .empty-state-message h1 {
		font-size: 3rem;
	}

	/*Shrink for empty and loading states from top*/
	.customer .pristine-state-message
	.customer .empty-state-message,
	.customer .spinner {
		margin-top: 100px;
		padding: 0 30px;
	}
}

.customer-activity {
	@extend .search-view;
	height: 100%;
}

.customer-activity .quick-result-container {
	height: 100%;
}

.customer-activity .search-results-container {
	display: block;
}

.customer-activity .filters {
	padding: 20px 30px;
}

.customer-activity .filters > * {
	display: inline-block;
}

.customer-activity .filters > * > span:first-child {
	display: inline-block;
}

.customer-activity .filters .dropdown {
	width: auto;
}

.customer-activity .spinner {
	margin-top: 0;
}

.verification-code {
	font-size: 1.3em;
	font-weight: bold;
	color: var(--color-highlight);
}

.alert-band button {
	display:inline-block;
	width:auto;
	margin: 0 0 0 20px;
}

.table-spaced td {
	padding:2px 25px;
}

.table-spaced td:first-child {
	text-align: right;
}
