/*==========================================================================================
	TAKEOVER MESSAGE
==========================================================================================*/
.takeover-message {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: var(--background-normal);

	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s ease-in-out,
	opacity 0.2s ease-in-out;
}

.takeover-message.visible {
	visibility: visible;
	opacity: 1;
}

.takeover-message > * {
	max-width: 600px;
	text-align: center;
}

.takeover-message a {
	text-decoration: none;
}

.takeover-message a:hover {
	text-decoration: underline;
}

.takeover-message-icon {
	margin-bottom: 50px;
	height: 225px;
}

.takeover-message-title {
	font-size: 4rem;
	font-weight: 300;
}

.takeover-message-text {
	margin: 20px 0;
	font-size: 3rem;
	font-weight: 300;
	color: var(--color-normal);
}

.takeover-message-action {
	font-size: 2rem;
	font-weight: 300;
}
