/* Layout */
.abo-tab-title-container {
	display: flex;
	flex-direction: horizontal;
}

[abo-tab-title] {
	display: block;
	padding: 10px 0;
	text-transform: uppercase;
	border-bottom: 2px solid transparent;
	margin-right: 30px;
	cursor: pointer;
}

[abo-tab-title].disabled {
	cursor: default;
}

[abo-tab-body] {
	display: none;
}

[abo-tab-body].active {
	display: block;
}

abo-tab-body-container {
	margin-top: 15px;
}

/* Style */
[abo-tab-title] {
	cursor: pointer;
	user-select: none;
	font-weight: bold;

	color: var(--color-tab-title);
	border-bottom-color: transparent;
	transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

[abo-tab-title]:hover {
	color: var(--color-tab-title-hover);
}

[abo-tab-title].active {
	border-bottom-color: black;
	color: var(--color-tab-title-active);
}

[abo-tab-title].disabled {
	color: var(--color-disabled-light);
}

/* Animations */
[abo-tab-body] {
	transition: opacity 0.5s ease-in-out;
}

[abo-tab-body].active-add,
[abo-tab-body].active-remove.active-remove-active {
	display: block;
	opacity: 0;
}

[abo-tab-body].active-add.active-add-active,
[abo-tab-body].active-remove {
	opacity: 1;
}

/* Overlap the previous and current tab body */
.abo-tab-body-container {
	position: relative;
}

[abo-tab-body].active-remove {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
