/*==========================================================================================
	Headings
==========================================================================================*/
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 2rem;
	line-height: 1.2;
	font-weight: 100;
}

h2 {
	font-size: 1.25rem;
	line-height: 1;
	font-weight: bold;
}

h3 {
	font-size: 1rem;
	line-height: 1;
	font-weight: bold;
}

h4 {
	font-size: 0.85rem;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
}
