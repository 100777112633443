/*==========================================================================================
	MODAL COMPONENT
==========================================================================================*/
/*Layout*/
.modal-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	/* center modal */
	text-align: center;
}

/*Spacing*/
.modal-dialog {
	/* anchor the loader */
	position: relative;
	/* use width of contents */
	display: inline-block;
	max-width: 90vw;
	overflow: auto;
	/* reset text-align from modal-centering */
	text-align: left;

	margin: 180px auto 0;
	padding: 25px 30px;
	@media (max-height: 600px) {
		margin-top: 30px;
	}
}

.modal-body {
	max-height: calc(80vh - 180px);
	overflow-y: auto;

	@media (max-height: 600px) {
		max-height: calc(80vh - 30px);
	}
}

.modal-header {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid var(--separator-light);
}

.modal-content {
	max-height: calc(80vh - 180px);

	@media (max-height: 600px) {
		max-height: calc(80vh - 30px);
	}
}

/*Style*/
.modal-overlay {
	background-color: rgba(0, 0, 0, 0.3);
}

.modal-dialog {
	background-color: var(--background-stained);
	border-radius: 3px;
}

.modal-title {
	color: var(--color-modal-title);
	font-weight: bold;
}

.modal-body {
	font-size: 1rem;
}

.modal-button-bar {
	margin-top: 15px;
	padding-top: 15px;
	text-align: right;
}

.modal-button-bar button:not(:last-child) {
	margin-right: 20px;
}

.modal-dialog section {
	padding: 15px 0;
	max-width: 70vw;
	overflow: auto;
}

.modal-dialog .modal-band {
	padding:0 15px;
	background-color: var(--background-modal-band);
}

/*Animations*/
.modal-overlay {
	transition: opacity 0.3s ease-in-out;
}

.modal-overlay.ng-hide {
	opacity: 0;
}

.modal-body .Add-security-phone-number .intl-tel-input {
	width: 100%;
}
