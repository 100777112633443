/*==========================================================================================
	GENERIC
==========================================================================================*/
html {
	height: 100%;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	height: 100%;
	font-family: 'adobe-clean', sans-serif;
	background-color: var(--background-normal);
	color: var(--color-normal);
}

/*==========================================================================================
	APP LAYOUT
==========================================================================================*/
.app-container {
	display: flex;
	flex-direction: column; /*Stack app header and view container*/
	height: 100%;
	min-height: 400px;
}

.app-container .app-header-container {
	flex: 0 0 auto;

	visibility: hidden;
	opacity: 0;
	transition: visibility 0.15s ease-in-out,
	opacity 0.15s ease-in-out;
}

.app-container .app-header-container.visible {
	visibility: visible;
	opacity: 1;
}

.app-container .app-view-container {
	flex: 1 1 auto; /*Fill remaining space unoccupied by .app-header-container*/
	display: flex; /*All .app-view-container elements will be flex items*/
	overflow-y: auto;
}

.app-container .app-view-container > * {
	flex: 1 1 auto; /*All .app-view-container elements will stretch*/
	overflow-y: auto;
}

.app-container .app-waiting-container {
	position: relative;
}

.app-container .app-waiting-container > .spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/*==========================================================================================
	APP HEADER
==========================================================================================*/
.app-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	font-size: 1.125rem;
	color: var(--color-header);
	background-color: var(--background-header);
	border-bottom: 1px solid var(--separator-header);
}

/*=== Logo and title ===*/
.app-logo-container {
	position: relative;
	padding-left: 50px;
	padding-right: 25px;
}

.app-logo-container::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -15px;
	width: 30px;
	height: 30px;
	background: url('logo.svg') no-repeat 50% 50%;
}

.app-logo-container h1 {
	margin: 0;
	font-size: 1.25rem;
	font-weight: inherit;
}

/*=== Title animation ===*/
@keyframes abo-title-initial {
	0% {
		color: var(--color-light);
	}
	100% {
		color: var(--color-header);
	}
}

@keyframes abo-title-ending {
	0% {
		max-width: 0;
		color: var(--background-header);
	}
	100% {
		max-width: 100px;
		color: var(--color-header);
	}
}

.app-logo-animation {
	@nest & .abo-title-initial {
		display: inline-block;
		vertical-align: middle;
		color: var(--color-light);
		animation: abo-title-initial 0.5s ease-in-out forwards 3s;
	}
	@nest & .abo-title-ending {
		display: inline-block;
		vertical-align: middle;
		max-width: 0;
		overflow: hidden;
		animation: abo-title-ending 0.5s ease-in-out forwards 3s;
	}
}

/*=== Navigation ===*/
.app-nav {
	flex: 1 1 auto; /*Take up all the horizontal space*/
	margin-left: 20px;
}

.app-nav li {
	display: inline-block;
	text-transform: uppercase;
	margin-right: 20px;
}

.app-nav li:last-child {
	margin-right: 0;
}

.app-nav li a {
	font-size: 1.25rem;
	font-weight: bold;
	display: block;
	color: inherit;
	text-decoration: none;
	padding: 5px 0;
	border-bottom: 2px solid transparent; /*Prevent flicker due to border showing up on hover*/
}

.app-nav li a.selected,
.app-nav li a:hover {
	color: var(--color-light);
	border-bottom-color: var(--border-link);
}

/*=== Buttons ===*/
.button-list.horizontal > button:not(:first-child),
.button-list.horizontal > a:not(:first-child) {
	margin-left: 30px;
}

/*=== Links ===*/
a {
	color: var(--color-link);
	text-decoration: none;
	transition: color 0.15s ease-in-out;
	cursor: pointer;
}

a:hover {
	color: var(--color-hover);
}

a:active,
a:focus {
	color: var(--color-active);
}

a.disabled {
	pointer-events: none;
	cursor: default;
	color: var(--color-disabled)
}

/*=== Current User ===*/
.app-current-user-container {
	display: flex;
	align-items: center;
}

.app-current-user-container .text-button {
	flex: 0 0 auto; /*Don't grow, don't shrink*/
	margin: 0 0 0 15px; /*15px from username, 10px to align nicely with the search magnifying glass in Customer Search and ABO Agents views.*/
	color: inherit;
}

.app-current-user-container .text-button:hover {
	color: var(--color-light);
}

.app-current-user-container .app-logout-button {
	font-size: 1.5rem;
}

/*=== Responsive App Header ===*/
@media (max-width: 850px) {
	/*Promote navigation to its own row on small screens.*/
	.app-header-container {
		flex-wrap: wrap;
	}
	.app-nav {
		order: 1;
		flex: 1 0 100%;
		margin-top: 20px;
		margin-left: 0;
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 450px) {
	/*Reduce some spacings*/
	.app-header-container {
		padding: 20px 10px;
	}
	.app-logo-container {
		padding-left: 40px; /*Leaves 10px between ABO logo and title*/
	}
	.app-current-user-container .current-user-avatar {
		margin-left: 10px; /*Distance between avatar and username*/
	}
	.app-current-user-container :not(.app-logout-button) {
		display: none;
	}

	/*Remove animation and shorten ABO title to only display the initials*/
	.app-logo-animation {
		@nest & .abo-title-initial {
			color: inherit;
			animation: none;
		}
		@nest & .abo-title-ending {
			animation: none;
		}
	}

	/*Stack nav menu vertically*/
	.app-nav ul {
		flex: 1 1 auto;
	}
	.app-nav li {
		display: block;
		margin-right: 0;
		margin-top: 15px;
		text-align: center;
	}
	.app-nav li:first-child {
		margin-top: 0;
	}
	.app-nav li a.selected,
	.app-nav li a:hover {
		border-bottom-color: transparent;
	}
}

/*=== Alerts ===*/
.alert-band {
	padding: 10px;
	margin: 20px;
	border-radius: 4px;
	text-align: left;
}

.alert-band.info {
	color: var(--color-light);
	background-color: var(--background-info);
}
.alert-band.warning {
	color: var(--color-light);
	background-color: var(--background-warning);
}

/*==========================================================================================
	FIXES
==========================================================================================*/
/* ng-animate forces ng-repeat to keep stale items alongside fresh ones for the duration of the transition 
   ex: on CustomerSearch results, due to hover transition on rows, duplicated data is shown by repeated searches
*/
[ng-repeat].ng-move,
[ng-repeat].ng-enter,
[ng-repeat].ng-leave {
	transition: none !important;
}

/* Sometimes we don't want borders on elements (ex: on certain abbr elements) */
.no-border {
	border: none !important;
}

.position-container {
	position: relative;
}

.full-width {
	width: 100%;
}

.pull-right {
	float: right;
}

.inline-item {
	margin-left: 2em;
}

/* it handles the case when there's a variable number of items */
.auto-inline-item + .auto-inline-item {
	margin-left: 2em;
}

.no-wrap {
	white-space: nowrap;
}

.head-room {
	margin-top: 1em;
}

.margin3px {
	margin: 3px;
}

.emphasized {
	font-weight: bold;
}

.error-text {
	color: var(--color-error);
}

[data-error-detect-red *= "error"], [data-error-detect-red *= "ERROR"] {
	color: var(--color-error);
}

.dot-before::before {
	content: "\2e30 ";
	margin-right: 0.25em;
}

.comma-separated:not(:last-of-type)::after {
	content: ', ';
	position: relative;
	display: inline;
}

.inline-explanation-for-radio-button {
	padding-left: 1em;
	margin-top: -0.5em;
}
