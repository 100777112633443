/*==========================================================================================
	CUSTOMER SEARCH VIEW
==========================================================================================*/
/*=== Layout ===*/
.search-view {
	display: flex;
	flex-direction: column;
}

.search-view .giant-search-input-container {
	/*Don't grow, don't shrink*/
	flex: 0 0 auto;
}

.search-view .search-results-wrapper {
	flex: 1 1 auto; /*Fill remaining space unoccupied by giant search input*/
	overflow-y: auto; /*Don't allow overflow*/
}

/*=== Search Results Empty State and Loading State ===*/
/*Spacing*/
.search-view .pristine-state-message,
.search-view .empty-state-message {
	padding: 0 50px;
	max-width: 600px;
	margin: auto;
}

.search-view .empty-state-message,
.search-view .spinner {
	margin-top: 150px;
}

.search-view .pristine-state-message {
	margin-top: 50px;
}

/*Pristine and Empty State*/
.search-view .pristine-state-message,
.search-view .empty-state-message {
	color: var(--color-subtle);
	font-size: 1.75rem;
	font-weight: 300;
	text-align: center;
}

.search-view .pristine-state-message .pristine-state-image {
	max-height: 25vh;
	max-width: 50vw;
}

.search-view .pristine-state-message h1,
.search-view .empty-state-message h1 {
	font-size: 4rem;
	font-weight: 300;
}

/*Responsiveness*/
@media (max-width: 450px) {
	.search-view .pristine-state-message
	.search-view .empty-state-message {
		font-size: 2rem;
	}
	.search-view .pristine-state-message h1,
	.search-view .empty-state-message h1 {
		font-size: 3rem;
	}

	/*Shrink for empty and loading states from top*/
	.search-view .pristine-state-message
	.search-view .empty-state-message,
	.search-view .spinner {
		margin-top: 100px;
		padding: 0 30px;
	}
}

/*=== Row Layout for results and quick profile ===*/
.search-view .search-results-wrapper {
	display: flex;
}

.search-view .search-results-container {
	flex: 1 1 auto;
	overflow: auto;
}

.search-view .quick-result-container {
	flex: 1 1 auto;
	max-width: 600px;
	min-width: 400px;

	@media (max-width: 1200px) {
		max-width: 500px;
		min-width: 375px;
	}
	@media (max-width: 1000px) {
		max-width: 400px;
		min-width: 320px;
	}
}

/*=== Search Results ===*/
.search-view .search-results-container {
	display: flex; /*Make .table-container a flex-item*/
}

.search-view /*.search-results-container*/ .table-container {
	flex: 1 1 auto;
	overflow: auto;
	overflow: overlay;
	font-size: 0.875rem;
}

/*Fixed table headers*/
.search-view .fixed-headers-table-context {
	padding-top: 85px; /*Match quick-result header height*/

	/*Match .master-table break-point*/
	@media (max-width: 905px) {
		padding-top: 0;
	}

	/*Background and border styles for header*/
	/*Since the header isn't visibile and the header lables are positioned, a before element of the context container is used.*/
	@nest &::before {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 85px;
		background-color: var(--background-stained);

		/*Match .master-table break-point*/
		@media (max-width: 905px) {
			display: none;
		}
	}
}

/*Responsiveness for .master-table*/
@media (max-width: 905px) {
	/*Make table elements stack*/
	.search-view .master-table,
	.search-view .master-table thead,
	.search-view .master-table tfoot,
	.search-view .master-table tbody,
	.search-view .master-table tr,
	.search-view .master-table th,
	.search-view .master-table td {
		display: block;
	}

	/*Get table header out of the way*/
	.search-view .master-table thead {
		position: absolute;
		top: -9999px;
		left: -9999px;
		width: 10px;
		height: 10px;
	}

	/*Spacing*/
	.search-view .master-table td {
		padding: 5px 0 0 0 !important; /*!important required to override :first-child and :last-child paddings*/
	}
	.search-view .master-table tr {
		padding: 10px;
	}

	/*Add labels*/
	.search-view .master-table td::before {
		content: attr(data-label);
		display: inline-block;
		padding-right: 8px;
		min-width: 80px;
		color: var(--color-table-header);
	}
}

.search-view .master-table tr.active {
	background-color: var(--background-row-active);
	cursor: default;
}

.search-view .master-table th {
	white-space: nowrap; /*Don't collapse whitespace to have a predictable height*/
}

.search-view .master-table .header-display-label {
	line-height: 85px; /*Match quick-result header height to center text vertically*/
}

/*Type table column*/
.search-view .master-table .column-account-type {
	width: 24px;
}

.search-view .master-table .account-type-icon {
	font-size: 1.5rem;
}

/*Error state*/
.customer-search-error {
	position: absolute;
	z-index: 100;
	left: 0;
	right: 0;
	color: var(--color-error);
	padding: 5px 10px;
	text-align: center;
}

/*=== Quick Profile ===*/
.search-view .quick-result-container {
	display: flex;
	flex-direction: column;
	border-left: 1px solid var(--separator-quick-result);
	background: var(--background-stained);
	overflow: hidden;
	height: 100%;

	@media (max-width: 600px) {
		display: none;
	}
}

/*Quick Profile Layout*/
.search-view .quick-result-container header {
	flex: 0 0 auto; /*Don't grow, don't shrink*/
}

.search-view /*.quick-result-container*/ .quick-result-sections-container {
	flex: 1 1 auto;; /*Fill remaining space unoccupied by the quick profile header*/
	overflow: auto;
}

/*Consistent padding for header and sections*/
.search-view .quick-result-container header,
.search-view .quick-result-container section {
	padding: 30px 40px 10px 40px;

	@media (max-width: 1200px) {
		padding: 30px 20px 10px 20px;
	}

	@media (max-width: 1000px) {
		padding: 30px 10px 10px 10px;
	}
}

/*Header*/
.search-view .quick-result-container header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background-color: var(--background-quick-result-header);

	/*Match master-table th header height*/
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 0;
	height: 85px;
	border-bottom: 1px solid var(--separator-quick-result); /*Match the 1px border of tr element in thead*/

	@media (max-width: 1000px) {
		flex-direction: column;
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}


/*Sections*/
.search-view .quick-result-container .spinner {
	margin-top: 0;
}

.search-view .quick-result-container section:not(:last-child) {
	border-bottom: 1px solid var(--separator-quick-result);
}

.search-view .quick-result-container /*section*/ .section-title {
	margin-bottom: 20px;
	font-size: 1rem;
	font-weight: normal;
	text-transform: uppercase;

	@media (max-width: 1000px) {
		font-size: 0.875rem;
	}
}

/*Section tables*/
.search-view .quick-result-container section table {
	width: 100%;
	margin-bottom: 10px;
}

.search-view .quick-result-container section th,
.search-view .quick-result-container section td {
	padding-bottom: 10px;
}

.search-view .quick-result-container section th {
	color: var(--color-table-header);
	white-space: nowrap; /*Prevent wrapping due to td being 100% wide*/
}

/*Input elements inside td elements should expand as much as possible*/
.search-view .quick-result-container section td > .form-input,
.search-view .quick-result-container section td > .form-input > input {
	display: inline-block;
	width: 100%;
}

.search-view .quick-result-container section td > .form-input {
	margin-bottom: 5px;
}

.search-view .quick-result-container section table + button {
	margin-left: -10px;
}

.search-view .quick-result-container button {
	white-space: nowrap;
}

.search-view .quick-result-container td:last-child > button:first-child {
	margin-left: 10px;
}

/*Responsiveness for section tables*/
@media (max-width: 1000px) {
	/*Stack table elements*/
	.search-view .quick-result-container section table
	.search-view .quick-result-container section tbody,
	.search-view .quick-result-container section tr,
	.search-view .quick-result-container section th,
	.search-view .quick-result-container section td {
		display: block;
		padding-left: 0;
		padding-right: 0;
	}
	.search-view .quick-result-container section th {
		padding-bottom: 0;
	}

	.search-view .quick-result-container section button {
		width: 100%;
	}
	.search-view .quick-result-container section table + button {
		margin-left: 0;
	}
	.search-view .quick-result-container section button + button {
		margin-top: 10px;
	}
}

/*=== Animations ===*/
.search-view .quick-result-container {
	&.ng-enter {
		transition: all 0.5s linear;
	}

	&.ng-enter-prepare,
	&.ng-enter,
	&.ng-leave.ng-leave-active {
		flex-grow: 0.1;
		width: 0;
	}

	&.ng-enter.ng-enter-active,
	&.ng-leave {
		flex-grow: 1;

		width: 600px;
		@media (max-width: 1200px) {
			width: 500px;
		}
		@media (max-width: 1000px) {
			width: 400px;
		}
	}
}
