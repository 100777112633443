.customer-search-view {
	@extend .search-view;
}

.customer-search-view .quick-result-container /*header*/ .selected-customer-name {
	flex: 0 0 auto;
	font-size: 1.5rem;
	font-weight: 300;
	max-width: 100%;

	@media (max-width: 1000px) {
		font-size: 1.25rem;
	}
}

.customer-search-view .quick-result-container /*header*/ .full-profile-button {
	flex: 0 1 auto;
	margin-left: 10px;
}

@media (max-width: 1200px) {
	.customer-search-view .quick-result-container /*header*/ .full-profile-button {
		max-width: 67px;
	}
	.customer-search-view .quick-result-container /*header*/ .full-profile-button .optional {
		display: none; /*Remove the word "full" from the "View full profile" button*/
	}
}

@media (max-width: 1000px) {
	.customer-search-view .quick-result-container /*header*/ .full-profile-button {
		max-width: none;
		width: 100%;
		margin-left: 0;
	}
}

.batch-disable-results-container {
	max-height: 25vh;
}
