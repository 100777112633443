.step-bar-section {
	display: flex;
	align-items: flex-end;
	counter-reset: step-bar-text;
}

.step-bar-section + .step-bar-section {
	padding-top: 0;
}

.step-bar-text {
	flex: 3 3 0;
	font-size: 125%;
	text-align: center;
	color: var(--step-bar-not-current);
	padding: 0 0.5em;
}

.step-bar-text::before {
	counter-increment: step-bar-text;
	content: counter(step-bar-text) '. ';
}

.step-bar-text:empty {
	/* visibility counts the element and keeps it in the flex-box but doesn't show the lonely counter */
	visibility: hidden;
}

.step-bar-text:first-child {
	flex: 2 2 0;
	text-align: left;
	padding-left: 0;
}

.step-bar-text:last-child {
	flex: 2 2 0;
	text-align: right;
	padding-right: 0;
}

.step-bar-text.step-bar-current {
	color: var(--step-bar-current);
}

.step-bar-bullet {
	flex: 0 0 1;
	width: 10px;
	height: 10px;
	background-color: var(--step-bar-current);
	border: 2px solid var(--step-bar-current);
	border-radius: 50%;
}

.step-bar-bullet.step-bar-bullet-current ~ .step-bar-bullet {
	background-color: transparent;
	border-color: var(--step-bar-not-current);
}

.step-bar-line {
	flex: 1 1 0;
	margin: 0 10px 5px;
	border-bottom: 2px var(--step-bar-current) solid;
}

.step-bar-bullet.step-bar-bullet-current ~ .step-bar-line {
	border-bottom-style: dashed;
	border-bottom-color: var(--step-bar-not-current);
}

