/* Layout */
[abo-accordion-item] {
	display: block;
	padding: 10px 20px;
}

[abo-accordion-body] {
	display: none;
}

[abo-accordion-item].open [abo-accordion-body] {
	display: block;
}

[abo-accordion-item] [abo-accordion-title]::before {
	@extend .icon-chevron.right::before;
	font-size: 1em;
	transition: transform 0.3s ease-in-out;
}

[abo-accordion-item].open [abo-accordion-title]::before {
	@extend .icon-chevron.down::before;
	font-size: 1em;
}

/* Style */
[abo-accordion-item]:nth-of-type(2n) {
	background-color: var(--background-stained);
}

[abo-accordion-title] {
	cursor: pointer;
	color: var(--color-accordion-title);
}

[abo-accordion-title]:hover {
	color: var(--color-accordion-title-hover);
}

[abo-accordion-title].disabled {
	cursor: default;
	color: var(--color-disabled)
}

/* Animate collapse */
/* Add transition on accordion item so ng-animate doesn't ignore it */
[abo-accordion-item] {
	transition-duration: 0.5s;
}

[abo-accordion-item] [abo-accordion-body] {
	transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
	overflow: hidden;
}

[abo-accordion-item].open-add [abo-accordion-body],
[abo-accordion-item].open-remove.open-remove-active [abo-accordion-body] {
	display: block;
	max-height: 0;
	opacity: 0;
}

[abo-accordion-item].open-add.open-add-active [abo-accordion-body],
[abo-accordion-item].open-remove [abo-accordion-body] {
	display: block;
	max-height: 100vh;
	opacity: 1;
}
