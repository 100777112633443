/*==========================================================================================
	ICONS
==========================================================================================*/
@font-face {
	font-family: 'abo-icons';
	src: url('abo-icons.eot');
	src: url('abo-icons.eot#iefix') format('embedded-opentype'),
	url('abo-icons.ttf') format('truetype'),
	url('abo-icons.woff') format('woff'),
	url('abo-icons.svg#abo-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

%icon {
	font-family: 'abo-icons';
	speak: none;
	font-size: 0.8em;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/*Better Font Rendering*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/*Allow transforms*/
	display: inline-block;
}

[class^='icon-'].big,
[class*=' icon-'].big {
	font-size: 2em;
}

[class^="icon-"] + span,
[class*=" icon-"] + span {
	margin-left: 0.3em;
}

[class^='icon-'].big + span,
[class*=' icon-'].big + span {
	margin-left: 1em;
}

.icon-search::before {
	@extend %icon;
	content: '\e900';
}

.icon-cross::before {
	@extend %icon;
	content: '\e901';
}

.icon-export::before {
	@extend %icon;
	content: '\e902';
}

.icon-logout::before {
	@extend %icon;
	content: '\e905';
}

.icon-warning::before {
	@extend %icon;
	content: '\e907';
}

/* Icon pointing right */
.icon-chevron::before {
	@extend %icon;
	content: '\e906';
}

.icon-chevron.left::before {
	@extend .icon-chevron::before;
	transform: rotate(180deg);
}

.icon-chevron.right::before {
	@extend .icon-chevron::before;
	transform: rotate(0deg);
}

.icon-chevron.down::before {
	@extend .icon-chevron::before;
	transform: rotate(90deg);
}
