/*==========================================================================================
	GIANT SEARCH INPUT
==========================================================================================*/
.giant-search-input-container {
	display: flex;
	align-items: center;
	background-color: var(--background-search);
}

.giant-search-input-container .giant-search-input {
	flex: 1 1 auto;
	padding: 20px;
	font-size: 2rem;
	font-weight: 300;
	text-align: center;
	background: transparent;
	border: none;
	color: var(--color-light);
}

.giant-search-input-container .giant-search-button {
	padding: 0 10px 0 20px;
	color: var(--color-light);
	font-size: 2rem;
}

.giant-search-input-container .giant-search-input::placeholder,
.giant-search-input-container .giant-search-button .icon-search {
	color: var(--color-light);
	opacity: 0.5;
}

@media (max-width: 500px) {
	.giant-search-input-container .giant-search-input,
	.giant-search-input-container .giant-search-button {
		font-size: 1.5rem;
	}
}

@media (max-width: 450px) {
	.giant-search-input-container .giant-search-input,
	.giant-search-input-container .giant-search-button {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/* Placeholder animation */
/* move placeholder to the left, to make room for text */
.giant-search-input::placeholder {
	transition: text-indent 0.3s ease-in-out;
	text-indent: 0;
}

.giant-search-input:focus::placeholder {
	text-indent: -8.75em;
}

/* Container smaller than twice the placeholder size, move placeholder all the way out of the screen */
@media (max-width: 700px) {
	.giant-search-input:focus::placeholder {
		text-indent: -700px;
	}
}

/* Firefox doesn't support animations or text-indent, so simply hide the placeholder on focus */
.giant-search-input:focus::-moz-placeholder {
	opacity: 0;
}
