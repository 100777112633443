/*==========================================================================================
	INPUT ELEMENTS
==========================================================================================*/
%form-element {
	box-sizing: border-box;
	border: 1px solid var(--border-normal);
	padding: 0 0 0.125em;
	text-indent: 0.75em;
	min-height: 2em;
	border-radius: 4px;
	outline: none;
	color: var(--color-normal);
	background: var(--background-normal);
	vertical-align: baseline;
	transition: all 0.15s ease-in-out;
}

input:not([type='file']),
textarea,
select {
	@extend %form-element;
}

input[type='radio'], input[type='checkbox'] {
	vertical-align: middle;
}

select {
	/* indent pushes the text out of bounds in select fields */
	text-indent: 0;
	padding-left: 0.75em;
	/* Match usual input width */
	min-width: 150px;
}

textarea {
	/* indent works on the first line only */
	padding: 0.5em 0.75em;
	text-indent: 0;
	vertical-align: middle;
	min-height: 69px;
	min-width: 280px;
	resize: none;
}

textarea.taller {
	min-height: 140px;
}

input:not([type='file']):hover,
textarea:hover,
select:hover {
	border-color: var(--border-hover);
}

input:not([type='file']):active,
input:not([type='file']):focus,
textarea:active,
textarea:focus,
select:active,
select:focus {
	border-color: var(--border-active);
}

/* Error state */
input:not([type='file']).ng-invalid,
textarea.ng-invalid,
select.ng-invalid {
	border-color: var(--color-error);
	padding-right: 2em;
	background: url("error.svg") no-repeat right 0.5em center / 1em;
}

input:not([type='file']):disabled,
textarea:disabled,
select:disabled {
	pointer-events: none;
	cursor: default;
}

input:not([type='file']):disabled,
input:not([type='file']):read-only,
textarea:disabled,
textarea:read-only,
select:disabled {
	background: var(--background-disabled);
	color: var(--color-disabled);
	border-color: transparent;
}

[ng-messages] {
	position: absolute;
	color: var(--color-error);
	font-size: 0.75em;
}

.form-field > span:first-child {
	color: var(--color-label);
}

.form-field {
	@extend .layout-columns-asymmetric;
}

.form-field-related-to-previous {
	padding-top: 0 !important;
	margin-top: -10px;
}

.form-field-related-to-previous span {
	padding-top: 0 !important;
}


/* Wrapper around an input with error messages */
.form-input {
	position: relative;
}

.form-value {
	@extend %form-element;
	display: inline-block;
	background-color: transparent;
	border-color: transparent;
	white-space: nowrap;
	padding-top: 5px;
}

span.form-value {
	text-indent: 0;
	white-space: normal;
}

/* ================ DROPDOWN =============== */
.dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	transition: all 0.15s ease-in-out;
}

.dropdown > select {
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 100%; /* Use width of .dropdown */
	padding-right: 2em; /* Leave space for the arrow */

	cursor: pointer;
}

.dropdown > i {
	position: absolute; /* Align icon background to a square at the end of the select */
	top: 0;
	right: 0;
	bottom: 0;
	width: 2em;
	pointer-events: none; /* Click through the arrow on the select */
	border-radius: 0 4px 4px 0; /* Mimic select border radius */

	color: var(--color-dropdown-arrow);
	transition: all 0.15s ease-in-out;
}

.dropdown:hover > i {
	color: var(--color-label);
}

.dropdown > select:disabled + i {
	display: none
}

.dropdown > i {
	@extend %icon;
}
.dropdown > i::after {
	content: '\e904';
	font-size: 0.5rem;

	/* Align icon in the middle of everything */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.dropdown.light select {
	color: var(--color-light)
}

.dropdown.quiet select {
	background-color: transparent;
	border-color: transparent;
}

/* Dropdown might use a separate span for display */
.dropdown > span + select {
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.dropdown > span:first-child {
	@extend select;
	@extend .dropdown > select;

	line-height: 2em;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: bottom;
}

.dropdown.light > span:first-child {
	@extend .dropdown.light select;
}

.dropdown.quiet > span:first-child {
	@extend .dropdown.quiet select;
}

/*==========================================================================================
	FORM ELEMENTS
==========================================================================================*/
.with-visible-button-bar {
	padding-bottom: 80px;
}

.button-bar {
	display: none;
}

.with-visible-button-bar > .button-bar {
	display: flex;
}

.button-bar {
	flex-direction: horizontal;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 80px;
	background-color: white;
	box-shadow: 0 1px 30px var(--shadow-grey);
}

.button-bar-info, .button-bar-buttons {
	flex: 1;
	padding: 0 50px;
}

.button-bar-info {
	font-style: italic;
	color: var(--color-subtle);
}

.button-bar-buttons {
	text-align: right;
}

.button-bar-buttons button {
	width: auto;
	margin: 5px 10px 5px 0;
}

@media(max-width: 1000px) {
	.button-bar-info,
	.button-bar-buttons {
		padding: 0 10px;
	}
}

@media(max-width: 700px) {
	.button-bar-info {
		display: none;
	}
}

/* Animations */
.with-button-bar {
	transition: padding-bottom 0.2s ease-in-out;
}

.button-bar {
	transition: max-height 0.2s ease-in-out;
	overflow: hidden;
}

.with-visible-button-bar-add.with-visible-button-bar-add-active > .button-bar,
.with-visible-button-bar-remove > .button-bar {
	display: flex;
	max-height: 80px;
}

.with-visible-button-bar-add > .button-bar,
.with-visible-button-bar-remove.with-visible-button-bar-remove-active > .button-bar {
	display: flex;
	max-height: 0;
}
