.agent-container {
	display: flex;
	flex-direction: column;
}

.agent-header {
	flex: 0;
	position: relative;
	background-color: var(--background-header);
	color: var(--color-header);
	text-align: center;
	padding: 25px 20px 10px 20px;
}

.agent {
	flex: 1 1;
}

.agent-header .go-back-link {
	position: absolute;
	left: 20px;
}

/* Remove go-back-link for small widths */
@media (max-width: 500px) {
	.agent-header .go-back-link {
		display: none;
	}
}

.agent-header .agent-name {
	font-size: 2.5rem;
	font-weight: 400;
	color: var(--color-light);
}

.agent-header .agent-picture {
	position: relative;
	margin: 20px auto 0;
	width: 64px;
}

.agent-header .agent-picture.warning img {
	opacity: 0.2;
}

.agent-header .agent-picture.warning::after {
	content: '';
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 0;
	margin-left: -25px;
	background: url('error.svg') no-repeat;
}

/*=== Navigation ===*/
/* Layout and spacing */
.agent-header .agent-nav {
	padding: 15px 0;
}

.agent-header .agent-nav > ul {
	display: inline-flex;
	flex-direction: row;
}

.agent-header .agent-nav li {
	margin-right: 30px;
}

.agent-header .agent-nav li:last-child {
	margin-right: 0;
}

.agent-header .agent-nav li a {
	display: block;
	padding: 5px 0;
	border-bottom: 2px solid transparent; /*Prevent flicker due to border showing up on hover*/
}

/* Style */
.agent-header .agent-nav li {
	text-transform: uppercase;
}

.agent-header .agent-nav li a {
	font-size: 1rem;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
}

.agent-header .agent-nav li a.selected,
.agent-header .agent-nav li a:hover {
	color: var(--color-light);
	border-bottom-color: var(--color-light);
}

/* ================ ACCORDION =============== */
/* Layout and spacing */
.agent .accordion-title-main {
	display: inline-block;
	min-width: 20vw;
	margin: 10px 30px 10px 10px;
}

/* Style */
.agent .accordion-title-main {
	font-size: 1.25rem;
	font-weight: bold;
}

.agent .accordion-title-details {
	font-size: 1rem;
	font-weight: normal;
	color: var(--color-subtle);
}

.agent [abo-accordion-item].open .accordion-title-details {
	visibility: hidden;
}

/* ================ FORM =============== */
.agent .form-field {
	padding: 5px 20px;
	min-height: 40px; /* Leave space for inline buttons */
}

.agent .form-field > :first-child {
	max-width: 200px;
}

.agent .form-field > :last-child {
	max-width: 500px;
}

.agent .form-value > button {
	margin-top: -5px;
}

.agent .form-field.buttons {
	padding-left: 260px; /* 200 width + 2x20 padding + 20 margin */
	display: block;
	min-height: 0;
	margin-top: -10px; /* closer to the top row */
}

.agent .form-field.buttons > * {
	display: inline-block;
	text-align: center;
	margin-top: 0;
	max-width: 500px;
}

.agent .form-field .form-field {
	padding: 0;
	min-height: 0;
}

.agent .form-field .form-field > span:first-child {
	padding: 0 0 3px;
	text-align: left;
}

.agent button {
	margin-right: 10px;
}

.agent .form-field > span:first-child {
	padding: 5px 20px 8px;
}

.agent input,
.agent textarea,
.agent .dropdown,
.agent .form-value {
	width: 100%;
}

/* Responsiveness - labels on top, wider fields */
@media (max-width: 1000px) {
	.agent .form-field > span:first-child {
		padding: 5px 0;
	}

	.agent button {
		display: block;
		width: 100%;
		margin: 5px 0;
	}

	.agent .form-field.buttons {
		flex-direction: column;
		padding-left: 20px;
	}
}

.agent .block-field {
	display: block;
}

.agent input[type="checkbox"] {
	width: auto;
}

.agent input[type="checkbox"]:disabled + span {
	color: var(--color-disabled);
}

.create-agent-form .form-value,
.create-agent-permissions .form-value {
	padding-top: 0;
	/* Fix alignment issues */
	margin-top: -6px;
	margin-bottom: 6px;

	text-indent: 0;
	padding-left: 0.75em;
}

.create-agent-form .form-value button,
.agent-permissions button {
	margin-left: 0.75em;
}




.create-agent-permissions .permissions-list {
	max-width: 500px;
	margin-bottom: 30px;
	padding-left: 75px;
}

.create-agent-permissions .permissions-list > * {
	display: inline-block;
	min-width: 200px;
	margin-right: 30px;
}

.quick-agent-permissions th {
	vertical-align: top;
	padding-top: 5px;
}

.quick-agent-permissions .permissions-list {
	display: block;
	margin-bottom: 30px;
}

.quick-agent-permissions .permissions-list > * {
	display: block;
}

.agent .permissions-list {
	@extend .agent .form-field.buttons;
	max-width: 500px;
	margin-bottom: 30px;
}

.agent .permissions-list > * {
	display: inline-block;
	min-width: 200px;
	margin-right: 30px;
}

/* ================ ACCORDION ITEMS =============== */
/* Layout */
.agent [abo-accordion-body] > div {
	margin: 20px 0;
}

.agent .centered-buttons {
	padding: 20px 10px 10px 10px;
	text-align: center;
}

.agent .pristine-state-message,
.agent .empty-state-message {
	color: var(--color-subtle);
	font-size: 1.75rem;
	font-weight: 300;
	text-align: center;
}

.agent .pristine-state-message .pristine-state-image {
	max-height: 25vh;
	max-width: 50vw;
}

.agent .pristine-state-message h1,
.agent .empty-state-message h1 {
	font-size: 4rem;
	font-weight: 300;
}

/*Responsiveness*/
@media (max-width: 450px) {
	.agent .pristine-state-message
	.agent .empty-state-message {
		font-size: 2rem;
	}
	.agent .pristine-state-message h1,
	.agent .empty-state-message h1 {
		font-size: 3rem;
	}

	/*Shrink for empty and loading states from top*/
	.agent .pristine-state-message
	.agent .empty-state-message,
	.agent .spinner {
		margin-top: 100px;
		padding: 0 30px;
	}
}

.agent-activity {
	@extend .search-view;
	height: 100%;
}

.agent-activity .quick-result-container {
	height: 100%;
}

.agent-activity .search-results-container {
	display: block;
}

.agent-activity .filters {
	padding: 20px 30px;
}

.agent-activity .filters > * {
	display: inline-block;
}

.agent-activity .filters > * > span:first-child {
	display: inline-block;
}

.agent-activity .filters .dropdown {
	width: auto;
}

.agent-activity .spinner {
	margin-top: 0;
}
