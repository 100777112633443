/*==========================================================================================
	SPINNERS
==========================================================================================*/
/* TODO: refactor this */
:root {
	--spinner-width: 2px;
}

.spinner {
	position: relative;
	width: 3em;
	height: 3em;
	font-size: 1rem;
	overflow: hidden;
}

/*Rotator*/
.spinner::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	transform: translate(-50%, -50%);
	border-width: var(--spinner-width);
	border-style: solid;
	border-color: var(--border-normal);
	border-top-color: var(--border-link);
	border-radius: 100%;
	animation: spin 0.6s ease-in-out infinite;
}

@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(359deg);
	}
}

/*Size variants*/
.spinner-small {
	font-size: 0.75rem;
}

.spinner-large {
	font-size: 2rem;
}

.spinner-huge {
	font-size: 3rem;
}

/*Layout variants*/
.spinner-centered-block {
	display: block;
	margin: 0 auto;
}

%spinner-cover {
	width: auto;
	height: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2);
}

%spinner-cover::before {
	width: 3em;
	height: 3em;
}

.spinner-full-screen {
	@extend %spinner-cover;
	position: fixed;
}

.spinner-full-screen::before {
	@extend %spinner-cover::before;
}

.spinner-overlaid {
	@extend %spinner-cover;
	position: absolute;
}

.spinner-overlaid::before {
	@extend %spinner-cover::before;
}

.spinner-anchor {
	position: relative;
}
