/*==========================================================================================
	HELPERS
==========================================================================================*/
/*Removes spacing and decoration from lists*/
.clean-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

/*Text variants*/
.text-warn {
	color: var(--color-warning);
}
