/*==========================================================================================
	TOAST COMPONENT
==========================================================================================*/
.toast-container {
	position: fixed;
	top: 0;
	width: 50%;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
}

.toast {
	float: left;
	clear: both;
	padding: 8px 10px 8px 10px;
	border-radius: 4px;
	font-weight: bold;
	margin: 15px 0 0 50%;
	transform: translateX(-50%);
	color: var(--color-toast-light);
	font-size: 0.875rem;
	background-color: transparent;
	white-space: nowrap;
}

.toast.toast-inline {
	display: inline-block;
	float: none;
	clear: none;
	margin: 0;
	text-indent: 0;
	transform: none !important;
}

.toast.SUCCESS {
	background-color: var(--background-toast-success);
}

.toast.WARNING {
	background-color: var(--background-toast-warning);
	color: var(--color-toast-dark);
}

.toast.ERROR {
	background-color: var(--background-toast-error);
}

.toast.ng-animate {
	/* Bounce */
	transition: all 0.15s cubic-bezier(0.175, 0.885, 0.320, 1.275) !important;
}

.toast.ng-enter,
.toast.ng-leave.ng-leave-active {
	transform: translate(-50%, -64px);
	opacity: 0;
}

.toast.ng-enter.ng-enter-active,
.toast.ng-leave {
	transform: translate(-50%, 0px);
	opacity: 1;
}
